<template>
  <div class="content">
    <div class="content_wrap_x">
      <div class="content_crumbs">
        <el-breadcrumb separator-icon="ArrowRight">
          <el-breadcrumb-item :to="{ path: '/' }">当前位置：集团要闻</el-breadcrumb-item>
          <el-breadcrumb-item>正文</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class=" content_content">
        <div class="content_content_title">
          {{ contentTitle }}
        </div>

        <div class="content_content_crumbs" :style="fixedPosition">
          <div class="content_content_crumbs_time">
            <img :src="contentCrumbs.timeIcon" />
            发布时间：{{ publishTime }}
          </div>
        </div>

        <div class="content_content_item_wrap">
          <div class="content_content_item">
            <p v-html="content"></p>
          </div>
        </div>

        <div class="content_content_fileInfo" v-for="fileItem in activeAttachList" :key="fileItem" @click="toHref(fileItem)">
          <div class="content_content_fileInfo_title">
            {{ fileItem.originalName }}
          </div>
          <div class="content_content_fileInfo_content">
            <img :src="contentIcon" />
            <div class="content_content_fileInfo_contetn_fileName">
              {{ fileItem.originalName }}
            </div>
          </div>
        </div>

        <div class="content_page_turner" v-if="haveTurner">
          <div class="content_page_turner_next">
            <span>下一篇</span>：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
          <div class="content_page_turner_before">
            <span>上一篇</span>：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onBeforeMount,
  onUnmounted,
} from "vue";
import imgData from "@/data";
import { stateStore, mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";
import allUrl from "@/config/url.js";

export default {
  props: ["haveFile", "haveTurner"],
  setup() {
    onBeforeMount(() => {
      window.addEventListener("scroll", () => {
        stateStore().setscrollX(document.documentElement.scrollTop);
      });
      const route = useRoute();
      stateStore().selectArticleById(route.query.id);
      // if (route.query.type) {
      //   document.title = route.query.type;
      // }
      // .then((res) => {
      //   document.title = res;
      // });
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", () => {
        console.log("remove");
      });
    });
    const fixedPosition = computed(() =>
      storeToRefs(stateStore()).scrollX.value > 400
        ? "position: fixed;top:0;right: 16%;width: 68%;"
        : ""
    );
    const router = useRouter();
    const storeOfstate = stateStore();
    const content = computed(() => {
      return storeToRefs(storeOfstate).activeHtml;
    });
    // const content = computed(() => localStorage.getItem("activeHtml"));
    const contentTitle = computed(() => {
      return storeToRefs(storeOfstate).activeTitle;
    });
    const publishTime = computed(
      () => storeToRefs(storeOfstate).activePublishTime
    );
    // const contentTitle = computed(() => localStorage.getItem("activeTitle"));
    // const publishTime = computed(() =>
    //   localStorage.getItem("activePublishTime")
    // );
    const ActiveItem = computed(() => storeToRefs(storeOfstate).activeItem);
    // const ActiveItem = computed(() =>
    //   JSON.parse(localStorage.getItem("activeItem"))
    // );
    const activeAttachList = computed(
      () => storeToRefs(storeOfstate).activeAttachList
    );
    const goHistory = () => {
      router.go(-1);
    };
    const toHref = (item) => {
      // window.open(location.origin + "/" + url);
      const a = document.createElement("a");
      a.href = location.origin + "/" + item.name;
      a.download = item.originalName;
      a.click();
    };
    const goToJoin = () => {
      window.open(allUrl.ServicePlatform);
    };
    const goIndex = () => {
      mainStore().setactivePageUrl("/index");
      router.push({
        path: "/index",
      });
    };
    const data = reactive({
      ...imgData.BiddingInfoImg,
      content,
      contentTitle,
      publishTime,
      ActiveItem,
      activeAttachList,
    });

    return {
      ...data,
      goHistory,
      goToJoin,
      goIndex,
      fixedPosition,
      toHref,
    };
  },
};
</script>

<style scoped>
@import url("./Content.css");
</style>

