<template>
  <div class="head_box">
    <div class="head_img">
      <div class="head_logo">
        <img :src="imgLogo ? imgLogo.link : './pic/bnannerLogo.png'" alt="" style="width: 100%;height:100%;object-fit: cover;">
      </div>
      <div class="head_text"> 山东英大招投标集团</div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import { getWebData } from "@/api/cmsSite/cmsSite.js"
export default {
  setup() {
    const imgLogo = ref({})
    const httpImg = async () => {
      const res = await getWebData()
      imgLogo.value = res.data.siteLogo
      console.log(res, 'imgLogo');
    }
    onMounted(async () => {
      await httpImg()
    })
    return {
      imgLogo,
      httpImg
    }
  }
};
</script>
<style scoped>
@import url("./imgHead.css");
</style>