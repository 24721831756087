<template>
  <div>
    <imgHead />
    <Header />
    <ContentView :haveFile="false" :haveTurner="false" />
    <Footer />
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import ContentView from "./components/ContentView/ContentView.vue";
import imgHead from "@/views/secondaryHead/compontents/img/imgHead.vue";
import Header from "@/views/secondaryHead/compontents/Header/Header.vue";
import Footer from "@/views/secondaryHead/compontents/Footer/Footer.vue";
import { stateStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: { ContentView, imgHead, Header, Footer },
  setup() {
    const storeOfstate = stateStore();
    const contentData = computed(() => {
      return storeToRefs(storeOfstate).activeHtml.value;
    });
    const contentTitle = computed(() => {
      return storeToRefs(storeOfstate).activeTitle.value;
    });

    const data = reactive({
      contentData,
      contentTitle,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
</style>

